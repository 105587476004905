export default {
  namespaced: true,

  state: {
      employes: [],
  },
  getters: {
    employes(state) {
          return state.employes.map((item, sl) => {
              item.sl = sl + 1;
              return item;
          })
      },
  },
  mutations: {
      setEmploye(state, employes) {
          state.employes = employes;
      },
  },

  actions: {
      async getEmployes(context) {
          let employes = await axios.get(`${this.state.host}/get-employe`, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
            .then(res => {
                  return res.data.employes;
              })
              .catch(error => {
                  this.dispatch('notification/error', error);
              });
          context.commit('setEmploye', employes);
      },
      async saveEmploye(context, employe) {
          let isSuccess = false;
          await axios.post(`${this.state.host}/store-employe`, employe, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {

                  context.dispatch('getEmployes');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });

          return isSuccess;
      },
      async statusUpdate(context, employe) {
          await axios.post(`${this.state.host}/employe-status-update`, employe, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getEmployes');
                  this.dispatch('notification/success', res.data.data);
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
      async employeDelete(context, employe) {
          await axios.post(`${this.state.host}/employe-delete`, employe, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getEmployes');
                  this.dispatch('notification/success', res.data.data);
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
  }
}
