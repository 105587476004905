export default {
    namespaced: true,

    state: {
        statment: [],
    },
    getters: {
        statment(state) {
            return state.statment
        },  
    },
    mutations: {
        setProfitLossStatment(state, statment) {
            state.statment = statment;
        },
    },

    actions: {
        async getProfitLossStatment(context, data){
            try {
                const res = await axios.post(`${this.state.host}/profit-loss-statement`, data,{
                    headers: {
                        Authorization: this.getters['user/GET_AUTH_TOKEN'],
                    },
                });
                context.commit('setProfitLossStatment', res.data);
                return true;
            } catch (error) {
                this.dispatch('notification/error', error.response.data.message);
                return false; 
            }
        },
    }
}
