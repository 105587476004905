export default {
    namespaced: true,

    state: {
        purchase: [],
        purchaseReturn:[],
        purchaseAllReturn:[],
    },
    getters: {
        purchase(state) {
            return state.purchase.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        purchaseAllReturn(state) {
            return state.purchaseAllReturn.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        purchaseReturn(state) {
            return state.purchaseReturn
        },
    },
    mutations: {
        setPurchase(state, purchase) {
            state.purchase = purchase;
        },
        setPurchaseReturn(state, purchaseReturn) {
            state.purchaseReturn = purchaseReturn;
        },
        setAllPurchaseReturn(state, purchaseAllReturn) {
            state.purchaseAllReturn = purchaseAllReturn;
        },
    },

    actions: {
        async getPurchase(context, data){
            let purchase = await axios.post(`${this.state.host}/get-purchase`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.purchase;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setPurchase', purchase);
        },
        async getPurchaseReturn(context, data){
            let purchaseReturn = await axios.post(`${this.state.host}/get-purchase-return`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.purchaseReturn;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setPurchaseReturn', purchaseReturn);
        },
        async getAllPurchaseReturn(context) {
            let allReturn = await axios.get(`${this.state.host}/get-all-purchase-return`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.allReturn;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setAllPurchaseReturn', allReturn);
        },
        async savePurchases(context, purchase) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-purchase`, purchase, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getPurchase');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
            return isSuccess;
        },
        async savePurchaseReturn(context, purReturn) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-purchase-return`, purReturn, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getPurchase');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
            return isSuccess;
        }
    }
}
