export default {
  namespaced: true,

  state: {
      accounts: [],
  },
  getters: {
      accounts(state) {
          return state.accounts.map((item, sl) => {
              item.sl = sl + 1;
              return item;
          })
      },
  },
  mutations: {
      setAccount(state, accounts) {
          state.accounts = accounts;
      },
  },

  actions: {
      async getAccounts(context) {
          let accounts = await axios.get(`${this.state.host}/get-account`, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  return res.data.accounts;
              })
              .catch(error => {
                  this.dispatch('notification/error', error);
              });
          context.commit('setAccount', accounts);
      },
      async saveAccount(context, account) {
          let isSuccess = false;
          await axios.post(`${this.state.host}/store-account`, account, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {

                  context.dispatch('getAccounts');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });

          return isSuccess;
      },
      async statusUpdate(context, account) {
          await axios.post(`${this.state.host}/account-status-update`, account, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getAccounts');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
      async accountDelete(context, account) {
          await axios.post(`${this.state.host}/account-delete`, account, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getAccounts');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
  }
}
