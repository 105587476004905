export default {
    namespaced: true,

    state: {
        categories: [],
    },
    getters: {
        categories(state) {
            return state.categories.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },
    mutations: {
        setCategory(state, categories) {
            state.categories = categories;
        },
    },
    actions: {
        async getCategories(context) {
            let categories = await axios.get(`${this.state.host}/get-category`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.categories;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setCategory', categories);
        },
        async saveCategory(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-category`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {

                    context.dispatch('getCategories');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async statusUpdate(context, category) {
            await axios.post(`${this.state.host}/catagory-status-update`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getCategories');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async catagoryDelete(context, category) {
            await axios.post(`${this.state.host}/catagory-delete`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getCategories');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
    }
}
