export default {
    namespaced: true,

    state: {
        users: [],
        permission: [],
    },
    getters: {
        users(state) {
            return state.users.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        permission(state){
            return state.permission;
        }
    },
    mutations: {
        setUnit(state, users) {
            state.users = users;
        },
        setPermission(state, permission) {
            state.permission = permission;
        },
    },

    actions: {
        async getUsers(context) {
            let users = await axios.get(`${this.state.host}/get-user`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.data;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setUnit', users);
        },
        async saveUser(context, data){
            let isSuccess = false;
                await axios.post(`${this.state.host}/user-create`, data, {
                    headers: {
                        Authorization: this.getters['user/GET_AUTH_TOKEN'],
                    },
                })
                    .then(res => {
                        context.dispatch('getUsers');
                        this.dispatch('notification/success', res.data.data);
                        return isSuccess = true;
                    })
                    .catch(error => {
                        this.dispatch('notification/error', error.response.data.message);
                    });
    
                return isSuccess;
          },
        async savePermission(context, data){
            let isSuccess = false;
            let sendData = {
                permission: JSON.stringify(data.permission),
                user_id: data.user_id,
            }
                await axios.post(`${this.state.host}/user-permission`, sendData, {
                    headers: {
                        Authorization: this.getters['user/GET_AUTH_TOKEN'],
                    },
                })
                    .then(res => {
                        this.dispatch('notification/success', res.data.data);
                        return isSuccess = true;
                    })
                    .catch(error => {
                        this.dispatch('notification/error', error.response.data.message);
                    });
    
                return isSuccess;
          },
          async getPermissionData(context, data){
            let isSuccess = false;
            let permissionData = await axios.post(`${this.state.host}/get-permission-data`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.commit('setPermission', res.data.data);
                isSuccess = true;
                return res.data.data;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            return isSuccess;
        },
    }
}
