export default {
    namespaced: true,

    state: {
        products: [],
        stockProducts: [],
        productStock: [],
        stock: [],
        openingStock: 0,
        ledgers: []
    },
    getters: {
        products(state) {
            return state.products.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        stockProducts(state) {
            return state.stockProducts.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        stock(state) {
            return state.productStock.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        openingStock(state) {
            return state.openingStock;
        },
        ledgers(state) {
            return state.ledgers;
        }
    },

    mutations: {
        setProduct(state, products) {
            state.products = products;
        },
        setStockProducts(state, stockProducts) {
            state.stockProducts = stockProducts;
        },
        setStock(state, productStock) {
            state.productStock = productStock;
        },
        setOpeningStock(state, stock) {
            state.openingStock = stock;
        },
        setProductLedger(state, ledgers) {
            state.ledgers = ledgers;
        }
    },
    actions: {
        async getproducts(context) {
            let products = await axios.get(`${this.state.host}/get-products`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.products;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setProduct', products);
        },
        async getProductStock(context) {
            let productStock = await axios.get(`${this.state.host}/get-product-stock`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.productStock;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setStock', productStock);
        },
        async stockProducts(context) {
            let stockProducts = await axios.get(`${this.state.host}/get-stock-products`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.stockProducts;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setStockProducts', stockProducts);
        },
        async generateProductCode(context) {
            let code = await axios.get(`${this.state.host}/generate-product-code`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            return code;
        },
        async saveProduct(context, product) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-product`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {

                    context.dispatch('getproducts');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async saveBulkProduct(context, product) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-bulk-product`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {

                    context.dispatch('getproducts');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async statusUpdate(context, product) {
            await axios.post(`${this.state.host}/product-status-update`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getproducts');
                    this.dispatch('notification/success', res.data.data);
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async productDelete(context, product) {
            await axios.post(`${this.state.host}/product-delete`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getproducts');
                    this.dispatch('notification/success', res.data.data);
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async getProductLedger(context, product) {
            await axios.post(`${this.state.host}/get-product-ledger`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.commit('setProductLedger', res.data.ledgers);
                context.commit('setOpeningStock', res.data.openingStock);

            })
            .catch(error => {
                this.dispatch('notification/error', error);
            })
        }
    }
}
