import { createStore } from "vuex";
import user from './user';
import product from './product';
import category from "./category";
import customer from "./customer";
import brand from './brand';
import unit from "./unit";
import area from "./area";
import supplier from "./supplier";
import purchase from "./purchase";
import account from "./account";
import income from "./income";
import expense from "./expense";
import employe from "./employe";
import sale from "./sale";
import menu from "./menu";
import usermanagment from "./usermanagment";
import dashboard from "./dashboard";
import report from "./report";
import notification from "./notification";
import createPersistedState from 'vuex-persistedstate';
import useConfig from '../composable/useConfig';

const { host, hostName } = useConfig()
export default createStore({
    state: () => {
        return {
            host: host,
            hostName: hostName,
        }
    },

    getters: {

    },

    mutations: {

    },

    actions: {

    },

    modules: {
        user,
        product,
        notification,
        category,
        brand,
        unit,
        customer,
        area,
        supplier,
        purchase,
        account,
        sale,
        income,
        expense,
        employe,
        menu,
        usermanagment,
        dashboard,
        report
    },
    plugins: [createPersistedState({
        paths: ['user']
      })],
})