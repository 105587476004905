export default {
    namespaced: true,

    state: {
        suppliers: [],
        supplierdue: [],
        supplierPaymentRecord: [],
        openingBalance: 0,
        ledgers: [],
    },
    getters: {
        suppliers(state) {
            return state.suppliers.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        supplierdue(state) {
            return state.supplierdue
        },
        supplierPaymentRecord(state) {
            return state.supplierPaymentRecord
        },
        ledgers(state) {
            return state.ledgers;
        },
        openingBalance(state) {
            return state.openingBalance;
        },
    },
    mutations: {
        setSupplier(state, suppliers) {
            state.suppliers = suppliers;
        },
        setSupplierDue(state, supplierdue) {
            state.supplierdue = supplierdue;
        },
        setSupplierPaymentRecord(state, supplierPaymentRecord) {
            state.supplierPaymentRecord = supplierPaymentRecord;
        },
        setSupplierLedger(state, ledgers) {
            state.ledgers = ledgers;
        },
        setOpeningBalance(state, stock) {
            state.openingBalance = stock;
        },
    },

    actions: {
        async getSupplier(context) {
            let suppliers = await axios.get(`${this.state.host}/get-supplier`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.suppliers;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setSupplier', suppliers);
        },
        async generateSupplierCode(context) {
            let code = await axios.get(`${this.state.host}/generate-supplier-code`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            return code;
        },
        async saveSupplier(context, product) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-supplier`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getSupplier');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });

            return isSuccess;
        },
        async statusUpdate(context, product) {
            await axios.post(`${this.state.host}/product-status-update`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getproducts');
                    this.dispatch('notification/success', res.data.data);
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async productDelete(context, product) {
            await axios.post(`${this.state.host}/product-delete`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getproducts');
                    this.dispatch('notification/success', res.data.data);
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async getSupplierDue(context, data){
            let purchaseReturn = await axios.post(`${this.state.host}/supplier-due-list`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setSupplierDue', purchaseReturn);
        },
        async getSupplierPaymentRecord(context, data){
            await axios.post(`${this.state.host}/supplier-payment-record`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.commit('setSupplierPaymentRecord', res.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
        },
        async getSupplierLedger(context, supplier){
            await axios.post(`${this.state.host}/supplier-ledger`, supplier,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.commit('setSupplierLedger', res.data.ledgers);
                context.commit('setOpeningBalance', res.data.openingStock);
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
        },
        async saveSupplierPayment(context, product) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/supplier-payment`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                // context.dispatch('getSupplier');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });

            return isSuccess;
        },
    }
}
