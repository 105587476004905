export default {
    namespaced: true,

    state: {
        units: [],
        activeUnits: [],
    },
    getters: {
        units(state) {
            return state.units.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        activeUnits(state) {
            return state.activeUnits.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
    },
    mutations: {
        setUnit(state, units) {
            state.units = units;
        },
        setActiveUnit(state, activeUnits) {
            state.activeUnits = activeUnits;
        },
    },

    actions: {
        async getUnits(context) {
            let units = await axios.get(`${this.state.host}/get-unit`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.units;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setUnit', units);
        },
        async getActiveUnit(context) {
            let units = await axios.get(`${this.state.host}/get-active-unit`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.units;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setActiveUnit', units);
        },
        async saveUnit(context, brand) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-unit`, brand, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getUnits');
                    context.dispatch('getActiveUnit');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async statusUpdate(context, brand) {
            await axios.post(`${this.state.host}/unit-status-update`, brand, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getUnits');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async unitDelete(context, unit) {
            await axios.post(`${this.state.host}/unit-delete`, unit, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getUnits');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
    }
}
