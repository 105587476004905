export default{
    namespaced: true,
    state:{
        sales: [],
        saleReturn: [],
    },
    getters:{
        sale(state) {
            return state.sales.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        saleReturn(state) {
            return state.saleReturn
        },
    },
    mutations:{
        setSale(state, sales) {
            state.sales = sales;
        },
        setSaleReturn(state, saleReturn) {
            state.saleReturn = saleReturn;
        },
    },
    actions:{
        async getSale(context, data){
            let sales = await axios.post(`${this.state.host}/get-sale`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.sales;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setSale', sales);
        },
        async getSaleReturn(context, data){
            let sales = await axios.post(`${this.state.host}/get-sale-return`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.saleReturn;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setSaleReturn', sales);
        },
        async saveSale(context, sale) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-sale`, sale, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getSale');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
            return isSuccess;
        },
        async saveSaleReturn(context, salereturn) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-sale-return`, salereturn, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getSaleReturn');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
            return isSuccess;
        },
    }
}