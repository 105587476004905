import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: "/",
    name: "login",
    component: () => import('../views/auth/login'),
    meta: { guest: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/deshboard/Dashboard'),
    meta: { auth: true }
  },
  // Product
  {
    path: '/product-list',
    name: 'productList',
    component: () => import('../views/product/product'),
    meta: { auth: true }
  },
  {
    path: '/product-bulk-import',
    name: 'productBulkImport',
    component: () => import('../views/product/productBulkImport'),
    meta: { auth: true }
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('../views/product/category'),
    meta: { auth: true }
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('../views/product/brand'),
    meta: { auth: true }
  },
  {
    path: '/unit',
    name: 'unit',
    component: () => import('../views/product/unit'),
    meta: { auth: true }
  },
  // inventory 
  {
    path: '/product-ledger',
    name: 'productLedger',
    component: () => import('../views/inventory/productLedger'),
    meta: { auth: true }
  },
  {
    path: '/product-stock',
    name: 'productStock',
    component: () => import('../views/inventory/productStock'),
    meta: { auth: true }
  },
  // Supplier
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('../views/supplier/supplier'),
    meta: { auth: true }
  },
  {
    path: '/supplier-ledger',
    name: 'supplierLedger',
    component: () => import('../views/supplier/supplierLedger'),
    meta: { auth: true }
  },
  {
    path: '/supplier-due',
    name: 'supplierDue',
    component: () => import('../views/supplier/supplierDue'),
    meta: { auth: true }
  },
  {
    path: '/supplier-payment',
    name: 'supplierPaymentRecord',
    component: () => import('../views/supplier/supplierPaymentRecord'),
    meta: { auth: true }
  },
  {
    path: '/supplier-payment/make-payment',
    name: 'makeSupplierPayment',
    component: () => import('../views/supplier/makeSupplierPayment'),
    meta: { auth: true }
  },
  {
    path: '/supplier-payment/edit-payment/:id?',
    name: 'editSupplierPayment',
    component: () => import('../views/supplier/editSupplierPayment'),
    meta: { auth: true }
  },

  // Purchase
  {
    path: '/purchase/:id?',
    name: 'purchase',
    component: () => import('../views/purchase/purchase'),
    meta: { auth: true }
  },
  {
    path: '/purchase-invoice/:id?',
    name: 'purchaseInvoice',
    component: () => import('../views/purchase/purchaseInvoice'),
    meta: { auth: true }
  },
  {
    path: '/purchase-record/:id?',
    name: 'purchaseRecord',
    component: () => import('../views/purchase/PurchaseRecord'),
    meta: { auth: true }
  },
  {
    path: '/purchase-return-record/returnable-list',
    name: 'PurchaseReturn',
    component: () => import('../views/purchase/PurchaseReturn'),
    meta: { auth: true }
  },
  {
    path: '/purchase-return-record/make-return/:id?',
    name: 'MakeReturn',
    component: () => import('../views/purchase/MakeReturn'),
    meta: { auth: true }
  },
  {
    path: '/purchase-return-record',
    name: 'PurchaseReturnRecord',
    component: () => import('../views/purchase/PurchaseReturnRecord'),
    meta: { auth: true }
  },
  // Sale
  {
    path: '/sale/:id?',
    name: 'Sale',
    component: () => import('../views/sale/Sale'),
    meta: { auth: true }
  },
  {
    path: '/sale-invoice/:id?',
    name: 'SaleInvoice',
    component: () => import('../views/sale/SaleInvoice'),
    meta: { auth: true }
  },
  {
    path: '/sale-record/:id?',
    name: 'SaleRecord',
    component: () => import('../views/sale/SaleRecord'),
    meta: { auth: true }
  },
  {
    path: '/sale-return-record/returnable-list',
    name: 'SaleReturn',
    component: () => import('../views/sale/SaleReturn'),
    meta: { auth: true }
  },
  {
    path: '/sale-return-record/make-return/:id?',
    name: 'SaleMakeReturn',
    component: () => import('../views/sale/MakeReturn'),
    meta: { auth: true }
  },
  {
    path: '/sale-return-record',
    name: 'SaleReturnRecord',
    component: () => import('../views/sale/SaleReturnRecord'),
    meta: { auth: true }
  },
  // Customer 
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('../views/customer/customer'),
    meta: { auth: true }
  },
  {
    path: '/customer-ledger',
    name: 'customerLedger',
    component: () => import('../views/customer/customerLedger'),
    meta: { auth: true }
  },
  {
    path: '/customer-due',
    name: 'customerDue',
    component: () => import('../views/customer/customerDue'),
    meta: { auth: true }
  },
  {
    path: '/customer-payment',
    name: 'customerPaymentRecord',
    component: () => import('../views/customer/customerPaymentRecord'),
    meta: { auth: true }
  },
  {
    path: '/customer-payment/make-payment',
    name: 'makeCustomerPayment',
    component: () => import('../views/customer/makeCustomerPayment'),
    meta: { auth: true }
  },
  {
    path: '/customer-payment/edit-payment/:id?',
    name: 'editCustomerPayment',
    component: () => import('../views/customer/editCustomerPayment'),
    meta: { auth: true }
  },
  // Account Managment
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('../views/accounts/accounts'),
    meta: { auth: true }
  },
  {
    path: '/expence',
    name: 'expence',
    component: () => import('../views/accounts/expence'),
    meta: { auth: true }
  },
  // Administration 
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/user'),
    meta: { auth: true }
  },
  {
    path: '/employee',
    name: 'employee',
    component: () => import('../views/user/employee'),
    meta: { auth: true }
  },
  {
    path: '/area',
    name: 'area',
    component: () => import('../views/user/area'),
    meta: { auth: true }
  },
  {
    path: '/profit-loss-statement',
    name: 'profitLossStatement',
    component: () => import('../views/report/profitLossStatement'),
    meta: { auth: true }
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');
  axios.get(`${store.state.host}/verification-token`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : null
        }
    })
    .then(res => {
    if(!res.data) {
      localStorage.removeItem('token')
          token = null;
          store.dispatch('user/forceLogout');
        router.push('/').catch(()=>{});
    }
    }).catch(e => {
        if(e.response.status == 401){
            localStorage.removeItem('token')
            store.dispatch('user/forceLogout');
            token = null;
            router.push('/');
        }
    })
  if (to.matched.some(record => record.meta.auth)) {
    if (!token) {
      next({
        name: 'login'
      })
    } else {
      const user_data = store.getters['user/GET_AUTH_INFO'];
      const permissionData = JSON.parse(user_data.permission);
      const type = user_data.type;

      if (type === 'admin') {
        next(); // Allow navigation for admin
      } else {
        const requestedLink = to.path;
        let hasPermission = false;

        // Check if any permission is a prefix of the requested path
        for (const item of permissionData) {
          if (requestedLink.startsWith(item.link)) {
            hasPermission = true;
            break;
          }
        }

        if (!hasPermission) {
          next({ path: permissionData[0].link }); // Redirect to the first permission path
        } else {
          next(); // Proceed with navigation
        }
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token) {
      next({
        name: 'dashboard'
      })
    }
    else {
      next()
    }
  }
})
export default router
