export default {
    namespaced: true,

    state: {
        area: [],
    },
    getters: {
        area(state) {
            return state.area.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        }
    },
    mutations: {
        setArea(state, area) {
            state.area = area;
        }
    },

    actions: {
        async getArea(context) {
            let area = await axios.get(`${this.state.host}/get-area`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.area;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setArea', area);
        },
        async saveArea(context, area) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-area`, area, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getArea');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async areaDelete(context, area) {
            await axios.post(`${this.state.host}/area-delete`, area, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getArea');
                this.dispatch('notification/success', res.data.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
    }
}
