export default {
  namespaced: true,

  state: {
      incomes: [],
  },
  getters: {
    incomes(state) {
          return state.incomes.map((item, sl) => {
              item.sl = sl + 1;
              return item;
          })
      },
  },
  mutations: {
      setIncome(state, incomes) {
          state.incomes = incomes;
      },
  },

  actions: {
      async getIncomes(context) {
          let incomes = await axios.get(`${this.state.host}/get-income`, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
            .then(res => {
                  return res.data.incomes;
              })
              .catch(error => {
                  this.dispatch('notification/error', error);
              });
          context.commit('setIncome', incomes);
      },
      async saveIncome(context, income) {
          let isSuccess = false;
          await axios.post(`${this.state.host}/store-income`, income, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {

                  context.dispatch('getIncomes');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });

          return isSuccess;
      },
      async statusUpdate(context, income) {
          await axios.post(`${this.state.host}/income-status-update`, income, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getIncomes');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
      async incomeDelete(context, income) {
          await axios.post(`${this.state.host}/income-delete`, income, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getIncomes');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
  }
}
