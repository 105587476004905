import { ref } from 'vue'

export const asideOpen = ref(true)
export const rightAsideOpen = ref(false)

const hostLocal = process.env.NODE_ENV === 'production' ? 'https://posdemo.featuredsoft.com/backend/api' : 'http://127.0.0.1:8000/api';
const hostNameLocal = process.env.NODE_ENV === 'production' ? 'https://posdemo.featuredsoft.com/backend' : 'http://127.0.0.1:8000';
export const host = hostLocal;
export const hostName = hostNameLocal;
export default function useConfig() {
    return {
        host: hostLocal,
        hostName: hostNameLocal,
    }
}