export default {
    namespaced: true,
    state: () => ({
        menuItems: [
            { label: 'Dashboard', icon: '/assets/img/icons/dashboard.svg', link: '/dashboard', isRouterLink: true },
            {
              label: 'Product',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                { label: 'Product', link: '/product-list', isRouterLink: true },
                { label: 'Product Bulk Import', link: '/product-bulk-import', isRouterLink: true },
                { label: 'Category', link: '/category', isRouterLink: true },
                { label: 'Brand', link: '/brand', isRouterLink: true},
                { label: 'Unit', link: '/unit', isRouterLink: true},
              ],
            },
            {
              label: 'Purchase',
              icon: '/assets/img/icons/purchase1.svg',
              submenu: true,
              submenuItems: [
                { label: 'Purchase', link: '/purchase', isRouterLink: true },
                { label: 'purchase-invoice', link: '/purchase-invoice/', isRouterLink: false },
                { label: 'Purchase Record', link: '/purchase-record', isRouterLink: true },
                { label: 'Purchase Return Record', link: '/purchase-return-record', isRouterLink: true },
              ],
            },
            {
              label: 'Supplier',
              icon: '/assets/img/icons/users1.svg',
              submenu: true,
              submenuItems: [
                { label: 'Supplier', link: '/supplier', isRouterLink: true },
                { label: 'Supplier Ledger', link: '/supplier-ledger', isRouterLink: true },
                { label: 'Supplier Due', link: '/supplier-due', isRouterLink: true },
                { label: 'Supplier Payment', link: '/supplier-payment', isRouterLink: true },
              ],
            },
            {
              label: 'Sale',
              icon: '/assets/img/icons/sales1.svg',
              submenu: true,
              submenuItems: [
                { label: 'Add Sale', link: '/sale', isRouterLink: true },
                { label: 'sale-invoice', link: '/sale-invoice/', isRouterLink: false },
                { label: 'Sale Record', link: '/sale-record', isRouterLink: true },
                { label: 'Sale Return Record', link: '/sale-return-record', isRouterLink: true },
              ],
            },
            {
              label: 'Inventory',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                { label: 'Product Stock', link: '/product-stock', isRouterLink: true },
                { label: 'Product Ledger', link: '/product-ledger', isRouterLink: true },
              ],
            },
            {
              label: 'Customer',
              icon: '/assets/img/icons/users1.svg',
              submenu: true,
              submenuItems: [
                { label: 'Customer', link: '/customer', isRouterLink: true },
                { label: 'Customer Ledger', link: '/customer-ledger', isRouterLink: true },
                { label: 'Customer Due', link: '/customer-due', isRouterLink: true },
                { label: 'Customer Payment', link: '/customer-payment', isRouterLink: true },
              ],
            },
            {
              label: 'Accounts',
              icon: '/assets/img/icons/expense1.svg',
              submenu: true,
              submenuItems: [
                { label: 'Account', link: '/accounts', isRouterLink: true },
                { label: 'Expence', link: '/expence', isRouterLink: true },
              ],
            },
            {
              label: 'Administration',
              icon: '/assets/img/icons/places.svg',
              submenu: true,
              submenuItems: [
                { label: 'User Management', link: '/user', isRouterLink: true },
                { label: 'Employee Management', link: '/employee', isRouterLink: true },
                { label: 'Area', link: '/area', isRouterLink: true },
              ],
            },
            {
              label: 'Report',
              icon: '/assets/img/icons/time.svg',
              submenu: true,
              submenuItems: [
                { label: 'Profit Loss Statement', link: '/profit-loss-statement', isRouterLink: true },
              ],
            },
          ],
    }),

    getters: {
        menuItems(state) {
            return state.menuItems;
        } 
    },

    mutations: {
        setMentItem(state, menuItem) {
            state.menuItems = menuItem
        }
    },

    actions: {

    }
  }
  