export default {
    namespaced: true,
  
    state: {
        totalSale: 0,
        totalSaleDue: 0,
        totalPurchaseDue: 0,
        totalPurchase: 0,
    },
    getters: {
        totalSale(state) {
            return state.totalSale;
        },
        totalSaleDue(state) {
            return state.totalSaleDue;
        },
        totalPurchaseDue(state) {
            return state.totalPurchaseDue;
        },
        totalPurchase(state) {
            return state.totalPurchase;
        },
    },
    mutations: {
        setTotalSale(state, saleTotal) {
            state.totalSale = saleTotal > 0 ? saleTotal : 0;
        },
        setTotalSaleDue(state, saleTotaldue) {
            state.totalSaleDue = saleTotaldue > 0 ? saleTotaldue : 0;
        },
        setTotalPurchaseDue(state, totalPurchaseDue) {
            state.totalPurchaseDue = totalPurchaseDue > 0 ? totalPurchaseDue : 0;
        },
        setTotalPurchase(state, totalPurchase) {
            state.totalPurchase = totalPurchase > 0 ? totalPurchase : 0;
        },
    },
  
    actions: {
        async getDashboardData(context) {
            let data = await axios.get(`${this.state.host}/get-system-info`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
              .then(res => {
                context.commit('setTotalSale', res.data.data.sale.total_paid);
                context.commit('setTotalSaleDue', res.data.data.sale.total_due);
                context.commit('setTotalPurchase', res.data.data.purchase.total_paid);
                context.commit('setTotalPurchaseDue', res.data.data.purchase.total_due);
                return res.data;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
    }
  }
  