export default {
    namespaced: true,

    state: {
        customer: [],
        openingBalance: 0,
        ledgers: [],
        customerdue: [],
        customerPaymentRecord: [],
    },
    getters: {
        customer(state) {
            return state.customer.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
        ledgers(state) {
            return state.ledgers;
        },
        openingBalance(state) {
            return state.openingBalance;
        },
        customerdue(state) {
            return state.customerdue
        },
        customerPaymentRecord(state) {
            return state.customerPaymentRecord
        },
    },
    mutations: {
        setCustomer(state, customer) {
            state.customer = customer;
        },
        setCustomerLedger(state, ledgers) {
            state.ledgers = ledgers;
        },
        setOpeningBalance(state, stock) {
            state.openingBalance = stock;
        },
        setCustomerDue(state, customerdue) {
            state.customerdue = customerdue;
        },
        setCustomerPaymentRecord(state, customerPaymentRecord) {
            state.customerPaymentRecord = customerPaymentRecord;
        },
    },

    actions: {
        async getCustomerDue(context, data){
            let purchaseReturn = await axios.post(`${this.state.host}/customer-due`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setCustomerDue', purchaseReturn);
        },
        async getcustomer(context) {
            let customer = await axios.get(`${this.state.host}/get-customers`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.customer;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            context.commit('setCustomer', customer);
        },
        async generateCustomerCode(context) {
            let code = await axios.get(`${this.state.host}/generate-customer-code`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
            return code;
        },
        async saveCustomer(context, customer) {
            try {
                const res = await axios.post(`${this.state.host}/store-customers`, customer, {
                    headers: {
                        Authorization: this.getters['user/GET_AUTH_TOKEN'],
                    },
                });
                context.dispatch('getcustomer');
                this.dispatch('notification/success', res.data.data);
                return true; // Return true indicating success
            } catch (error) {
                this.dispatch('notification/error', error.response.data.message);
                return false; // Return false indicating failure
            }
        },
        async statusUpdate(context, customer) {
            await axios.post(`${this.state.host}/customer-status-update`, customer, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getcustomer');
                    this.dispatch('notification/success', res.data.data);
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async customerDelete(context, customer) {
            await axios.post(`${this.state.host}/customer-delete`, customer, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getcustomer');
                this.dispatch('notification/success', res.data.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
        async getCustomerLedger(context, customer){
            await axios.post(`${this.state.host}/get-customer-ledger`, customer,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.commit('setCustomerLedger', res.data.ledgers);
                context.commit('setOpeningBalance', res.data.openingStock);
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
        },
        async getCustomerPaymentRecord(context, data){
            await axios.post(`${this.state.host}/customer-payment-record`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.commit('setCustomerPaymentRecord', res.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error);
            });
        },
        async saveCustomerPayment(context, product) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/customer-payment`, product, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                // context.dispatch('getSupplier');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });

            return isSuccess;
        },
    }
}
