export default {
  namespaced: true,

  state: {
    expences: [],
  },
  getters: {
    expences(state) {
          return state.expences.map((item, sl) => {
              item.sl = sl + 1;
              return item;
          })
      },
  },
  mutations: {
      setExpense(state, expences) {
          state.expences = expences;
      },
  },

  actions: {
      async getExpenses(context) {
          let expenses = await axios.get(`${this.state.host}/get-expense`, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
            .then(res => {
                  return res.data.expences;
              })
              .catch(error => {
                  this.dispatch('notification/error', error);
              });
          context.commit('setExpense', expenses);
      },
      async saveExpense(context, expense) {
          let isSuccess = false;
          await axios.post(`${this.state.host}/store-expense`, expense, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {

                  context.dispatch('getExpenses');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });

          return isSuccess;
      },
      async statusUpdate(context, expense) {
          await axios.post(`${this.state.host}/expense-status-update`, expense, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getExpenses');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
      async expenseDelete(context, expense) {
          await axios.post(`${this.state.host}/expense-delete`, expense, {
              headers: {
                  Authorization: this.getters['user/GET_AUTH_TOKEN'],
              },
          })
              .then(res => {
                  context.dispatch('getExpenses');
                  this.dispatch('notification/success', res.data.data);
                  return isSuccess = true;
              })
              .catch(error => {
                  this.dispatch('notification/error', error.response.data.message);
              });
      },
  }
}
