export default {
    namespaced: true,

    state: {
        brands: [],
    },
    getters: {
        brands(state) {
            return state.brands.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },
    },
    mutations: {
        setBrand(state, brands) {
            state.brands = brands;
        },
    },

    actions: {
        async getBrands(context) {
            let brands = await axios.get(`${this.state.host}/get-brand`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.brands;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
            context.commit('setBrand', brands);
        },
        async saveBrand(context, brand) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-brand`, brand, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {

                    context.dispatch('getBrands');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async statusUpdate(context, brand) {
            await axios.post(`${this.state.host}/brand-status-update`, brand, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getBrands');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
        async brandDelete(context, brand) {
            await axios.post(`${this.state.host}/brand-delete`, brand, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getBrands');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
    }
}
