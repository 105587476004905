import fuse from 'fuse.js'
export default {
    data: function(){
        return {
            categoryGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['name','description'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            employeGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['name',
                    'phone',
                    'email',
                    'nid',
                    'joining_date',
                    'designation',],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            productGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['product_name','code','barcode','description','brand.name','category.name','unit.name'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            purchesRecordGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['date','invoice','supplier.name','total','paid','due','user.name'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            purchesReturnGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['purchase.date','date','invoice','supplier.name','total','note','user.name'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            customersListGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['code','name','credit_limit','phone','email','office_name','opening_balance','address', ''],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            suppliersListGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['code','name',,'phone','email','address', 'opening_balance'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            accountsListGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['account_name','account_number','bank_name','branch_name','description','oppening_balance','total_cash_in','total_cash_out'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            incomeGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['transaction_id','date','account','description','category','amount','added_by','updated_by'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            expenseGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['transaction_id','date','account','description','category','amount','added_by','updated_by'],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            stockGlobalFilter: function (filterableItem, term, display_text) {
                const fuseOptions = {
                    isCaseSensitive: false,
                    includeScore:false,
                    useExtendedSearch: true,
                    shouldSort:true,
                    findAllMatches: true,
                    ignoreLocation: true,
                    threshold: 0,
                    keys: ['product_name',
                    'category.name',
                    'brand.name',
                    'stock.purchase_quantity',
                    'stock.purchase_return',
                    'stock.sale_quantity',
                    'stock.sale_return',
                    'stock.transfer_from_quantity',
                    'stock.damage_quantity',
                    'stock.current_stock',
                    ],
                }
                const fuseObj = new fuse([filterableItem], fuseOptions)
                const op = fuseObj.search(term).map(item => item.item)
                if (op.length > 0) {
                    return true
                }
                return false
            },
            formatDate(dateString) {
                dateString = new Date(dateString)
                const day = dateString.getDate().toString().padStart(2, "0");
                const month = (dateString.getMonth() + 1)
                  .toString()
                  .padStart(2, "0");
                const year = dateString.getFullYear().toString();
    
                return `${day}-${month}-${year}`;
          },
          numTotext(amountToWord) {
                var words = new Array();
                words[0] = '';
                words[1] = 'One';
                words[2] = 'Two';
                words[3] = 'Three';
                words[4] = 'Four';
                words[5] = 'Five';
                words[6] = 'Six';
                words[7] = 'Seven';
                words[8] = 'Eight';
                words[9] = 'Nine';
                words[10] = 'Ten';
                words[11] = 'Eleven';
                words[12] = 'Twelve';
                words[13] = 'Thirteen';
                words[14] = 'Fourteen';
                words[15] = 'Fifteen';
                words[16] = 'Sixteen';
                words[17] = 'Seventeen';
                words[18] = 'Eighteen';
                words[19] = 'Nineteen';
                words[20] = 'Twenty';
                words[30] = 'Thirty';
                words[40] = 'Forty';
                words[50] = 'Fifty';
                words[60] = 'Sixty';
                words[70] = 'Seventy';
                words[80] = 'Eighty';
                words[90] = 'Ninety';
                let amount = amountToWord == null ? '0.00' : amountToWord.toString();
                var atemp = amount.split(".");
                var number = atemp[0].split(",").join("");
                var n_length = number.length;
                var words_string = "";
                if (n_length <= 9) {
                    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
                    var received_n_array = new Array();
                    for (var i = 0; i < n_length; i++) {
                        received_n_array[i] = number.substr(i, 1);
                    }
                    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                        n_array[i] = received_n_array[j];
                    }
                    for (var i = 0, j = 1; i < 9; i++, j++) {
                        if (i == 0 || i == 2 || i == 4 || i == 7) {
                            if (n_array[i] == 1) {
                                n_array[j] = 10 + parseInt(n_array[j]);
                                n_array[i] = 0;
                            }
                        }
                    }
                    let value = "";
                    for (var i = 0; i < 9; i++) {
                        if (i == 0 || i == 2 || i == 4 || i == 7) {
                            value = n_array[i] * 10;
                        } else {
                            value = n_array[i];
                        }
                        if (value != 0) {
                            words_string += words[value] + " ";
                        }
                        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                            words_string += "Crores ";
                        }
                        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                            words_string += "Lakhs ";
                        }
                        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                            words_string += "Thousand ";
                        }
                        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                            words_string += "Hundred and ";
                        } else if (i == 6 && value != 0) {
                            words_string += "Hundred ";
                        }
                    }
                    words_string = words_string.split("  ").join(" ");
                }
                if (String(words_string).length > 0) {
                    return words_string + ' TK Only';
                } else {
                    return 'Zero' + ' TK Only';
                }
            },
        }
    },
    methods: {}
}